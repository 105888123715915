import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'src/components/Seo'
import { Wysiwyg, Container, Section } from 'src/components/Base'

import { Hero } from 'src/sections'

const Home = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const content = page.atspLegalPage.legalContent

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Container>
          <Wysiwyg>{content}</Wysiwyg>
        </Container>
      </Section>
    </>
  )
}

export const pageQuery = graphql`
  query legalById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }

      atspLegalPage {
        legalContent
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
    }
  }
`

export default Home
